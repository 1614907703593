import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store";
import NProgress from "nprogress";
import {loginByTrail} from "@/services/LoginService";
let xhr = new XMLHttpRequest();
//解决点击相同菜单报NavigationDuplicated错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  // 登录
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "@/views/Login"),
  },
  // 首页
  {
    path: "/",
    name: "HomeIndex",
    meta: {
      hasBar: true,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "HomeIndex" */ "@/views/homeIndex"),
  },
  // 首页标签搜索页面
  {
    path: "/Home-search",
    name: "HomeSearch",
    meta: {
      hasBar: false,
      hasFooter: true,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "HomeIndex" */ "@/views/homeSearch.vue"),
  },
  // 资源详情
  {
    path: "/resource-detail/:productId",
    name: "ResourceDetail",
    meta: {
      hasBar: true,
    fatherName: ["HomeIndex", "HomeSearch"]
    },
    component: () =>
      import(/* webpackChunkName: "ResourceDetail" */ "@/views/ResourceDetail"),
  },
  // 支付
  {
    path: "/payment",
    name: "Payment",
    meta: {
      fatherName: ["HomeIndex", "HomeSearch"]
    },
    component: () =>
      import(/* webpackChunkName: "Payment" */ "@/views/Payment"),
  },
  {
    path: "/check-product-detail/:id/:type",
    name: "CheckProductDetail",
    component: () =>
      import(/* webpackChunkName: "Payment" */ "@/views/checkProductDetail"),
  },
  {
    path: "/adm",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "Admin" */ "@/views/Admin.vue"),
    children: [
      {
        path: "personal-details",
        name: "PersonalDetails",
        meta: {
          hasPersonBar: true,
          nowNames: "账号信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "ProjectManagement" */ "@/views/PersonalDetails"
          ),
      },
      {
        path: "modify-personal-details/:type",
        name: "ModifyPersonalDetails",
        meta: {
          fatherName: "PersonalDetails",
          fatherNames: "账号信息",
          nowNames: "修改账号信息",
          hasPersonBar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "ModifyPersonalDetails" */ "@/views/modifyPersonalDetail"
          ),
      },
      {
        path: "inventory",
        name: "Inventory",
        meta: {
          fatherName: "BigData",
          fatherNames: "大数据统计",
          nowNames: "课件清单",
          hasPersonBar: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "ModifyPersonalDetails" */ "@/views/Inventory"
                ),
      },
      {
        path: "setting",
        name: "Setting",
        meta: {
          hasPersonBar: true,
          nowNames: "系统配置"
        },
        component: () =>
            import(/* webpackChunkName: "MyShopping" */ "@/views/Setting"),
      },
      {
        path: "message",
        name: "Message",
        meta: {
          hasPersonBar: true,
          nowNames: "信息通知"
        },
        component: () =>
            import(/* webpackChunkName: "Message" */ "@/views/message"),
      },
      {
        path: "shopping-car",
        name: "MyShopping",
        meta: {
          hasPersonBar: true,
          nowNames: "我购买的"
        },
        component: () =>
          import(/* webpackChunkName: "MyShopping" */ "@/views/MyShopping"),
      },
      {
        path: "tag",
        name: "Tag",
        meta: {
          hasPersonBar: true,
          nowNames: "标签管理"
        },
        component: () =>
            import(/* webpackChunkName: "Message" */ "@/views/Tag"),
      },
      {
        path: "order",
        name: "Order",
        meta: {
          hasPersonBar: true,
          nowNames: "历史订单"
        },
        component: () =>
            import(/* webpackChunkName: "Message" */ "@/views/Order"),
      },
      {
        path: "ID-message",
        name: "IDMessage",
        meta: {
          hasPersonBar: true,
          nowNames: "账号管理"
        },
        component: () =>
            import(/* webpackChunkName: "Message" */ "@/views/IDMessage"),
      },
      {
        path: "big-data",
        name: "BigData",
        meta: {
          hasPersonBar: true,
          nowNames: "大数据统计"
        },
        component: () =>
            import(/* webpackChunkName: "Message" */ "@/views/BigData"),
      },
      {
        path: "my-issue",
        name: "MyIssue",
        meta: {
          hasPersonBar: true,
          nowNames: "我上架的"
        },
        component: () =>
            import(/* webpackChunkName: "MyIssue" */ "@/views/MyIssue"),
      },
      {
        path: "issue-detail/:id",
        name: "IssueDetail",
        meta: {
          fatherName: "MyIssue",
          fatherNames: "我上架的",
          hasPersonBar: true,
          nowNames: "使用详情"
        },
        component: () =>
            import(/* webpackChunkName: "MyIssue" */ "@/views/IssueDetail"),
      },
      // 作品管理
      {
        path: "works",
        name: "Works",
        meta: {
          hasPersonBar: true,
          nowNames: "作品管理"
        },
        component: () =>
          import(/* webpackChunkName: "Works" */ "@/views/Works"),
      },
      // 已通过
      {
        path: "already-passed",
        name: "AlreadyPassed",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AlreadyPassed" */ "@/views/alreadyPassed"
          ),
      },
      // 已驳回
      {
        path: "already-reject",
        name: "AlreadyReject",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AlreadyReject" */ "@/views/alreadyReject"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "HomeIndex" && to.name !=="ResourceDetail") {
    window.scrollTo(0,0);
  }
  //如果没有上一级页面，那么nowRouterName是自己，如果有上一级页面，那么childRouterNames就是直接，那么nowRouterName是自己是父页面
  let params = {
    nowRouterName: to.meta.fatherName ? to.meta.fatherName : to.name,
    childRouterName: to.meta.fatherName ? to.name : null,
    nowNames: to.meta.fatherName ? to.meta.fatherNames : to.meta.nowNames,
    childRouterNames: to.meta.fatherName ? to.meta.nowNames : null,
  }
  console.log(store.state.isMyPage)
  console.log(from.name)
  store.dispatch("nowRouter", params);
    if (!store.state.isMyPage || !from.name) {
      xhr.open("post","https://utp.veryengine.cn/User/HasLogin");
      xhr.withCredentials = true;
      xhr.send();
      xhr.onreadystatechange = function (){
        if (xhr.readyState === 4&& xhr.status === 200){
          let resp = JSON.parse(xhr.response);
          if (resp.code === "00000"){
            const data = resp.data;
            const params = {
              nickname: data.userName,
              outId: data.userId,
              wechatId: data.wechat ? data.wechat : "",
              wechatName: data.userName,
              photo: data.avatar,
              email: data.email ? data.email : "",
              mobile: data.phone ? data.phone : "",
            };
            localStorage.setItem("nickName", data.userName);
            if (params) {
              loginByTrail(params).then(resp=> {
                if (resp.data.code === "SUCCESS") {
                  const data = resp.data.data;
                  localStorage.setItem("userId", data.userId);
                  localStorage.setItem("profilePictureUrl", data.profilePictureUrl);
                  localStorage.setItem("roleIds", data.roleIds);
                  localStorage.setItem("token", data.token);
                  store.state.isMyPage = true;
                  console.log(store.state.isMyPage)
                  setTimeout(()=> {
                    NProgress.start();
                    next();
                  },1000)
                }else {
                  NProgress.start();
                  next();
                }
              })
            }else {
              NProgress.start();
              next();
            }
          }else if(to.name === "HomeIndex" || to.name === "HomeSearch" || to.name === "ResourceDetail") {
            localStorage.removeItem("userId");
            localStorage.removeItem("nickName");
            localStorage.removeItem("token");
            store.dispatch("openVuex", "");
            store.dispatch("checkId", "817485bf52754d8880cb97789f88141d");
            store.dispatch("checkSideMenu", "edit");
            store.state.isMyPage = true;
            NProgress.start();
            next();
          }else {
            localStorage.removeItem("userId");
            localStorage.removeItem("nickName");
            localStorage.removeItem("token");
            store.dispatch("openVuex", "");
            store.dispatch("checkId", "817485bf52754d8880cb97789f88141d");
            store.dispatch("checkSideMenu", "edit");
            store.state.isMyPage = true;

            if(to.name !== "Login") {
              next({
                replace:true,
                name:'Login',
              })
            } else { next(); }
          }
        }else if (xhr.readyState === 4&& xhr.status !== 200) {
          NProgress.start();
          next();
        }
      }
    }else {
      NProgress.start();
      next();
    }
});
router.afterEach(() => {
  NProgress.done();
  // 定时器解决 部分翻译问题
  let num = 0;
  let intervalName = null;
  intervalName = setInterval(() => {
    if(num < 10) {
      translate.execute();
      num ++;
    } else {
      clearInterval(intervalName)
    }
  }, 500)
});

export default router;
